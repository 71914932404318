import Motion from '../Motion'
import Props from '../Properties'
import * as esz from '../Ease'
import Circle from '../geom/Circle'
import Colors from '../Colors'

export default function () {
  const bgColor = document.querySelector('#bgColor')!
  const motionBgClr = new Motion(bgColor, 0)
  motionBgClr.addKey(0, {
    backgroundColor: Colors.grey.W700,
  })
  motionBgClr.holdPrev(888)
  motionBgClr.setEas(esz.s.EASE_IN2)
  motionBgClr.addKey(999, {
    backgroundColor: Colors.yellow.W700,
  })
  motionBgClr.holdPrev(666)
  motionBgClr.setEas(esz.s.EASE_IN2)
  motionBgClr.addKey(999, {
    backgroundColor: Colors.grey.W900,
  })
  motionBgClr.holdPrev(999)
  motionBgClr.setEas(esz.s.EASE_IN2)
  motionBgClr.addKey(999, {
    backgroundColor: Colors.yellow.W800,
  })
  motionBgClr.setEas(esz.s.EASE_IN2)
  motionBgClr.addKey(999, {
    backgroundColor: Colors.grey.W700,
  })
  motionBgClr.holdPrev(555)
  motionBgClr.setEas(esz.s.EASE_IN2)
  motionBgClr.addKey(999, {
    backgroundColor: Colors.yellow.W700,
  })
  motionBgClr.holdPrev(3000)
  motionBgClr.setEas(esz.s.EASE_IN2)
  motionBgClr.addKey(2222, {
    backgroundColor: Colors.yellow.W100,
  })
  motionBgClr.holdPrev(5000)
  motionBgClr.setEas(esz.s.EASE_IN2)
  motionBgClr.addKey(1111, {
    backgroundColor: Colors.grey.W900,
  })
  motionBgClr.holdPrev(2000)
  motionBgClr.setEas(esz.s.EASE_IN2)
  motionBgClr.addKey(999, {
    backgroundColor: Colors.grey.W800,
  })
  motionBgClr.setFill('forwards')

  return motionBgClr
}
