import '~/../node_modules/web-animations-js/web-animations-next.min.js'
import HelloThere from './animations/HelloThere'
import Iam from './animations/Iam'
import AsoftwareDev from './animations/AsoftwareDev'
import AGeekByNature from './animations/AGeekByNature'
import ByHeart from './animations/ByHeart'
import Votis from './animations/Votis'
import Contact from './animations/Contact'
import Hire from './animations/Hire'
import Background from './animations/Background'

const scrolltainer = document.querySelector('#scrolltainer')! as HTMLDivElement
const time = document.querySelector('#time')! as HTMLDivElement
const tmline = document.querySelector('#timeline')! as HTMLDivElement
const world = document.getElementById('world')!

world.ondragstart = function () {
  return false
}
window.addEventListener('load', init)

//
function init() {
  let o: number
  const hellothere = HelloThere(999)
  o = (hellothere as any).activeDuration
  const iam = Iam(o - 700)
  o = (iam as any).activeDuration
  const votis = Votis(o - 200)
  o = (votis as any).activeDuration
  const aSoftDev = AsoftwareDev(o - 100)
  o = (aSoftDev as any).activeDuration
  const aGeek = AGeekByNature(o - 100)
  o = (aGeek as any).activeDuration
  const byHeart = ByHeart(o - 800)
  o = (byHeart as any).activeDuration
  const hire = Hire(o - 2500)
  o = (hire as any).activeDuration
  const contact = Contact(o - 600)
  const motionBgClr = Background()
  //
  const text = new GroupEffect([
    hellothere,
    iam,
    votis,
    aSoftDev,
    aGeek,
    byHeart,
    hire,
    contact,
  ])

  const gfx = new GroupEffect([text, motionBgClr.get()])

  const player: Animation = new Animation(gfx, document.timeline)
  // const totaltimet = player.effect?.getTiming().duration;
  // console.log("init -> totaltimet", totaltimet)
  // const totaltime = player.effect?.getComputedTiming().activeDuration ?? 1;
  const totaltime = 31939
  player.pause()
  player.play()
  player.onfinish = () => {
    wasPlaying = false
    scrolltainer.scrollTo(0, scrolltainer.scrollHeight)
  }

  let requestAnimationFrameID = NaN
  let currentTimeRatio = 0
  const lastScrollStamp = 0
  let lastDownlStamp = 0
  let wasPlaying: boolean

  //
  scrolltainer.ondblclick = () => location.reload()
  scrolltainer.onmousedown = handleDown
  scrolltainer.ontouchstart = handleDown
  scrolltainer.onmouseup = handlUp
  scrolltainer.ontouchend = handlUp
  scrolltainer.onscroll = onSroll
  window.addEventListener(
    'orientationchange',
    function () {
      if (player) {
        player.pause()
        updateScrollbar()
        player.play()
      }
    },
    false
  )

  function handleDown(e: any) {
    lastDownlStamp = e.timeStamp
    if (player?.playState === 'running') {
      wasPlaying = true
      player.pause()
      updateScrollbar()
    } else {
      wasPlaying = false
    }
  }
  function handlUp(e: any) {
    e.preventDefault()
    const d = e.timeStamp - lastDownlStamp
    if (d < 200) {
      if (wasPlaying) {
        player?.pause()
      } else {
        if (player.currentTime === totaltime) {
          player.currentTime = 0
        }
        player?.play()
      }
    }
  }

  function togglePlayback() {
    if (player?.playState === 'running') {
      player.pause()
      updateScrollbar()
    } else {
      player?.play()
    }
  }

  function updateScrollbar() {
    const c =
      (scrolltainer.scrollHeight - scrolltainer.clientHeight) * currentTimeRatio
    scrolltainer.scrollTop = c
  }

  function onTick(e: any) {
    updateUIs()
    requestAnimationFrame(onTick)
  }
  requestAnimationFrame(onTick)

  function onSroll(e: any) {
    // let d = e.timeStamp - lastScrollStamp;
    // lastScrollStamp = e.timeStamp;
    // console.log(d, isDown);

    const d = e.timeStamp - lastScrollStamp
    // lastScrollStamp = e.timeStamp;
    // console.log(d, isDown);

    if (!player) return

    player.pause()
    if (!requestAnimationFrameID) {
      requestAnimationFrameID = requestAnimationFrame(() => {
        requestAnimationFrameID = NaN
        //
        player.currentTime =
          (scrolltainer.scrollTop /
            (scrolltainer.scrollHeight - scrolltainer.clientHeight)) *
          totaltime
      })
    }
  }

  function updateUIs() {
    time.textContent = Math.round(player.currentTime ?? 0).toString()
    currentTimeRatio = player.currentTime! / totaltime
    tmline.style.transform = `translate(0, 100vh) scaleX(${currentTimeRatio})  translate(0,-100%)`
  }
}
